import React, { useEffect } from "react";
import { embedPageInDiv } from "../utilities/utils";
// import EmbeddedWebview from "../components/EmbeddedWebview";
const Secured = () => {

  useEffect(() => {
    const courseUrl =
      process.env.REACT_APP_DEMO_COURSE_URL_CROSS_DOMAIN;
    embedPageInDiv("embeddedHTMLPage", courseUrl);
  }, []);
  return (
    <div className="html-container">
      <div id="embeddedHTMLPage"></div>
    </div>
    // <EmbeddedWebview></EmbeddedWebview>
  );
};

export default Secured;
