import React, { useEffect } from "react";
import { embedPageInDiv } from "../utilities/utils";
const Secured = () => {
  useEffect(() => {
    const courseUrl = process.env.REACT_APP_DEMO_COURSE_URL_SAME_DOMAIN;
    embedPageInDiv("embeddedHTMLPage", courseUrl);
  }, []);
  return (
    <div className="html-container">
      <div id="embeddedHTMLPage"></div>
    </div>
  );
};

export default Secured;
