import { useKeycloak } from "@react-keycloak/web";
import React from "react";
import HomePage from '../pages/HomePage';
const PrivateRoute = ({ children }) => {
  const { keycloak } = useKeycloak();

  const isLoggedIn = keycloak.authenticated;

  // if (!isLoggedIn) {
  //   window.location.href = '/';
  // }
  return isLoggedIn ? children : <><HomePage></HomePage></>;
};

export default PrivateRoute;
