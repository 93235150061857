import React, { useEffect } from "react";
import './style.scss';
import { embedPageInDiv } from "../utilities/utils";
const Secured = () => {
  useEffect(() => {
    const courseUrl = process.env.REACT_APP_DEMO_COURSE_URL_SAME_DOMAIN;
    embedPageInDiv("embeddedHTMLPage", courseUrl);
  }, []);

  
  return (
    <>
      <div className="main-container">
        <div className="flex-row first-row">
          <div className="outer-container left-outer first-container">
            <div className="inner-container">First Container</div>
          </div>
          <div className="outer-container right-outer second-container">
            <div className="inner-container">Second Container</div>
          </div>
        </div>
        <div className="flex-row second-row">
          <div className="outer-container left-outer third-container">
            <div className="inner-container">Third Container</div>
          </div>
          <div className="outer-container right-outer html-container">
            <div id="embeddedHTMLPage"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Secured;
