import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Nav from "./components/Nav";
import { KeycloakProvider } from "@react-keycloak/web";
import WelcomePage from "./pages/HomePage";
import SecuredPage from "./pages/SecuredPage";
import SecuredPage2 from "./pages/SecuredPage2";
import SecuredPage3 from "./pages/SecuredPage3";
import SecuredPage4 from "./pages/SecuredPage4";
import PrivateRoute from "./helpers/PrivateRoute";
import keycloak from './utilities/keycloak';
import './App.scss';
function App() {

  const onKeycloakEvent = (event, error) => {
    console.debug("onKeycloakEvent", event, error);
  };

  const onKeycloakTokens = (tokens) => {
    localStorage.setItem("AuthToken", tokens.token);
  };
  return (
    <>
      <KeycloakProvider
        keycloak={keycloak}
        onEvent={onKeycloakEvent}
        onTokens={onKeycloakTokens}
        LoadingComponent={
          <div className="init-spinner">
            <div className="init-spinner-border"></div>
          </div>
        }
      >
        <>
          <Nav />
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<WelcomePage></WelcomePage>} />
              <Route
                path="/secured"
                element={
                  <PrivateRoute>
                    <SecuredPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/secured2"
                element={
                  <PrivateRoute>
                    <SecuredPage2 />
                  </PrivateRoute>
                }
              />
              <Route
                path="/secured3"
                element={
                  <PrivateRoute>
                    <SecuredPage3 />
                  </PrivateRoute>
                }
              />
              <Route
                path="/secured4"
                element={
                  <PrivateRoute>
                    <SecuredPage4 />
                  </PrivateRoute>
                }
              />
            </Routes>
          </BrowserRouter>
        </>
      </KeycloakProvider>
    </>
  );
}

export default App;

