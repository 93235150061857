export const embedPageInDiv = (container, courseUrl) => {
  if (window.$) {
    window.$(`#${container}`).load(courseUrl, function () {
      window.$(this).trigger("pagecreate");
    });
  } else {
    console.warn("jquery not loaded. Trying again in 100ms...");
    setTimeout(() => {
      embedPageInDiv(container, courseUrl);
    }, 100);
  }
};
