import * as React from "react";
import { useKeycloak } from "@react-keycloak/web";
import './Nav.scss';
const Nav = () => {
  const { keycloak } = useKeycloak();
  return (
    <>
      <div
        className="custom-nav"
      >
        {keycloak && (
          <>
            {!keycloak.authenticated && (
              <button
                className="nav-button login-btn"
                onClick={() => keycloak.login()}
              >
                Login
              </button>
            )}

            {!!keycloak.authenticated && (
              <button
                className="nav-button login-btn"
                onClick={() => {
                  setTimeout(() => {
                    localStorage.removeItem("AuthToken");
                  }, 0);

                  keycloak.logout();
                  setTimeout(() => {
                    window.location.href = "/";
                  }, 200);
                }}
              >
                Logout
                {/* ({keycloak.tokenParsed.preferred_username}) */}
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Nav;
