import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import "./HomePage.scss";
const Home = () => {
  const { keycloak } = useKeycloak();
  
  return (
    <div
      style={{ margin: "0 auto", width: "fit-content" }}
      className="home-page"
    >
      {!(keycloak && keycloak.authenticated) ? (
        <div>
          <h1>Welcome to the App</h1>

          <h3>Please login to see the course</h3>
        </div>
      ) : (
        <>
          <div className="heading">Welcome</div>
          <div className="all-links">
            {/* <div className="link-container">
              <div className="link-text">Iframe: Different Domain</div>
              <div className="link">
                <a href="/secured">Go</a>
              </div>
            </div>
            <div className="link-container">
              <div className="link-text">
                Embedded in div : Different Domain
              </div>
              <div className="link">
                <a href="/secured2">Go</a>
              </div>
            </div> */}
            <div className="link-container">
              <div className="link-text">Embedded in div</div>
              <div className="link">
                <a href="/secured3">Go</a>
              </div>
            </div>
            <div className="link-container">
              <div className="link-text">
                Embedded in div : within UI skeleton
              </div>
              <div className="link">
                <a href="/secured4">Go</a>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
