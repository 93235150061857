import React, { useRef } from "react";
const Secured = () => {
  const iframeRef = useRef();
  const courseUrl = process.env.REACT_APP_DEMO_COURSE_URL_CROSS_DOMAIN;

  const sendMessageToIframe = () => {
    if (iframeRef && iframeRef.current) {
      let courseIframe1 = iframeRef.current;
      try {
        const authToken = window.localStorage.getItem("AuthToken");
        if (authToken) {
          courseIframe1.contentWindow.postMessage(
            {
              action: "setSession",
              key: "AuthToken",
              value: authToken,
            },
            process.env.REACT_APP_COURSE_BASE_URL
          );
        } else {
          alert("Error! Couldn't find AuthToken");
        }
      } catch (e) {
        console.log(e);
        window.ee = e;
      }
    }
  };
  return (
    <div>
      <br></br>
      <div style={{ height: "80vh", width: "100vw", textAlign: "center" }}>
        <iframe
          onLoad={sendMessageToIframe}
          ref={iframeRef}
          id="courseIframe"
          title="Course"
          src={courseUrl}
          style={{
            height: "100%",
            width: "90%",
            border: "2px solid red",
            marginTop: "3rem",
          }}
        ></iframe>
      </div>
    </div>
  );
};

export default Secured;
